import React from 'react';


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li className="menu-item-has-children">
                    <a href={process.env.PUBLIC_URL + "/#index"}>Inicio </a>
                </li>
                <li className="menu-item-has-children">
                    <a href={process.env.PUBLIC_URL + "/#services"}>Servicios</a>
                </li>
                <li className="menu-item-has-children">
                    <a href={process.env.PUBLIC_URL + "/#about"}>Nosotros</a>
                </li>
                <li className="menu-item-has-children">
                    <a href={process.env.PUBLIC_URL + "/#experience"}>Experiencia</a>
                </li>
                <li className="menu-item-has-children">
                    <a href={process.env.PUBLIC_URL + "/#process"}>Proceso</a>
                </li>
                <li><a href={process.env.PUBLIC_URL + "/#about"}>Contacto</a></li>
            </ul>
        </nav>
    )
}

export default Nav;